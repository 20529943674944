<template>
  <div>
    <b-row class="mt-2 mx-4 mb-2">
      <div class="label-wrap pl-0 mb-0 pr-1 mr-3">
        <div :class="`${view_label && !is_required && isOptionBage ? '' : 'flex'}`">
          <div v-if="view_label && is_required" class="mr-2 badge badge-required">必須</div>
          <div v-if="view_label && !is_required && isOptionBage" :class="`mr-2 badge badge-required original-badge badge-type-${label_badge_type}`">{{ optionLabelBadgeText }}</div>
          <div v-if="view_label && !is_required && !isOptionBage && label_badge_type !== 0" class="mr-2 badge badge-optional">任意</div>
          <div class="inlineBlock bold label-text">{{ fieldLabel }}</div>
        </div>
        <div
          v-if="messagesEachType[5]"
          class="label-msg-wrap mt-2">
          <div
            v-for="(messageData, index) in messagesEachType[5]" :key="`msg_label_${index}`"
            :class="[
              'label-msg mb-1',
              `label-msg-view${messageData.view_template}`
            ]">{{messageData.msg}}</div>
        </div>
      </div>
      <div class="mainWidth">

        <b-row
          v-if="messages.length > 0"
          class="mt-2">
          <p
            class="mb-0 form-file-line"
            v-for="(data, index) in messages" :key="index">
            <span v-if="data.type === 1"
              :class="[
                `message_${data.view_template}`,
              ]"
              v-html="data.message"></span>
            <span
              v-if="data.type === 3"
              :class="[
                `message_${data.view_template}`,
              ]">
              ダウンロード：
              <b-link
                @click="downloadOpenFile(assetId(index), data.message)"
                class="mb-0">
                {{data.message}}
              </b-link>
            </span>
          </p>
        </b-row>
        <div v-if="type !== 100">
          <component
            :is="typeMap[type]"
            :fieldId='fieldId'
            :is_variable="is_variable"
            :input_number="input_number"
            :min_input_number="min_input_number"
            :max_input_number="max_input_number"
            :options.sync="options"
            :notice="notice"
            :writable="writable"
            :view_template="view_template"
            :rules="rules"
            :messages="messages"
            :params="params"
            :downloadFormFile="downloadFormFile"
            :controlFormatter="controlFormatter"
            :controlInputType="controlInputType"
            :inNoneInput="inNoneInput"
            :downloadApploadedFile="downloadApploadedFile"
            @kickSetText="setText($event)"
            @kickSetSelect="setSelect($event)"
            @kickSetFile="setFile($event)"
            @kickDeleteFile="deleteFile($event)"
            />
        </div>
        <div v-if="type === 100">
          <component
            :is="specialConponentNameList[view_template]"
            :fieldId='fieldId'
            :options.sync="options"
            :notice="notice"
            :writable="writable"
            :messages="messages"
            :params="params"
            :input_number="input_number"
            :min_input_number="min_input_number"
            :max_input_number="max_input_number"
            :downloadFormFile="downloadFormFile"
            :controlFormatter="controlFormatter"
            :controlInputType="controlInputType"
            :downloadApploadedFile="downloadApploadedFile"
            :inNoneInput="inNoneInput"
            @kickSetText="setText($event)"
            @kickSetSelect="setSelect($event)"
            @kickSetFile="setFile($event)"
            @kickDeleteFile="deleteFile($event)"
            />
        </div>
        <div
          v-if="messages.length > 0">
          <p
            v-for="(data, index) in messages" :key="index"
            class="mb-0">
            <!-- <b-col class="px-0" v-if="data.type === 2">
              <p class="mb-0 f-13">{{data.message}}</p>
            </b-col>

            <b-col class="px-0" v-if="data.type === 4">
              <p class="mb-0 f-13">{{data.message}}</p>
            </b-col> -->
            <span class="f-13"
              v-if="data.type === 2"
              :class="`bottom-msg-view${data.view_template}`">{{data.message}}</span>
            <span class="f-13"
              v-if="data.type === 6"
              :class="`bottom-msg-view${data.view_template} blue-text`">{{data.message}}</span>
            <span class="f-13"
              v-if="data.type === 7"
              :class="`bottom-msg-view${data.view_template}`">{{data.message}}</span>

            <span class="f-13 ml-3" v-if="data.type === 4">
              ダウンロード：
              <b-link
                @click="downloadOpenFile(assetId(index), data.message)"
                class="mb-0">
                {{data.message}}
              </b-link>
            </span>
          </p>
        </div>
        <div v-if="type === 6 && fieldLabel !== '助成金使途明細'">
          <p class="mb-0"><span class="f-13 bottom-msg-view2">＊A4縦でアップロードされていることをご確認ください。</span></p>
        </div>

      </div>
    </b-row>
  </div>
</template>
<script>
import Input from '@/components/parts/application/Input.vue';
import Textarea from '@/components/parts/application/Textarea.vue';
import Check from '@/components/parts/application/Check.vue';
import Radio from '@/components/parts/application/Radio.vue';
import File from '@/components/parts/application/File.vue';
import Table from '@/components/parts/application/Table.vue';
import Datetime from '@/components/parts/application/Datetime.vue';
import Selectbox from '@/components/parts/application/Selectbox.vue';
import Calendar from '@/components/parts/application/Calendar.vue';
import Collaborator from '@/components/parts/application/special/Collaborator_1.vue';
import Participant from '@/components/parts/application/special/Participant_2.vue';
import Destination from '@/components/parts/application/special/J_Destination_3.vue';
import Iterm from '@/components/parts/application/special/I_Term_4.vue';
import CONST_STATUS from '@/constants/specialField';
import LABEL_BADGE from '@/constants/labelBadge';

export default {
  name: 'PartsApplicationText',
  components: {
    'v-app-input': Input,
    'v-app-textarea': Textarea,
    'v-app-check': Check,
    'v-app-radio': Radio,
    'v-app-file': File,
    'v-app-table': Table,
    'v-app-datetime': Datetime,
    'v-app-selectbox': Selectbox,
    'a-app-calendar': Calendar,
    'v-app-collaborator': Collaborator,
    'v-app-participant': Participant,
    'v-app-destination': Destination,
    'v-app-i-term': Iterm,
  },
  props: {
    fieldId: { type: Number },
    fieldLabel: { type: String },
    description: { type: String, default: '' },
    notice: { type: String, default: '' },
    type: { type: Number },
    is_required: { type: [Boolean, Number], default: false },
    view_label: { type: [Boolean, Number], default: true },
    label_badge_type: { type: Number, default: null },
    // 記入項目マスタ
    label: { type: String, default: '' },
    is_variable: { type: [Boolean, Number], default: true },
    input_number: { type: Number, default: 1 },
    min_input_number: { type: Number, default: 1 },
    max_input_number: { type: Number, default: 1 },
    view_template: { type: Number, default: 1 },
    writable: { type: Boolean, default: false },
    options: {
      type: Array,
      default: () => {
        return [
          {
            id: '',
            label: '',
            // input_type: '',
            prefix: '',
            suffix: '',
            placeholder: '',
            parent_option_id: null,
            parent_choice_id: null,
            view_template: 1,
            max: null,
          },
        ];
      },
    },
    assets: { type: [Object, Array], default: () => {} },
    rules: { type: [Object, Array], fedault: () => {} },
    messages: { type: [Object, Array], default: () => [] },
    params: { type: [Object, Array], default: () => [] },
    downloadFormFile: { type: Function },
    downloadOpenFile: { type: Function },
    inNoneInput: { type: Boolean, default: false },
    downloadApploadedFile: { type: Function },
  },
  data() {
    return {
      typeMap: {
        1: 'v-app-input',
        2: 'v-app-textarea',
        3: 'v-app-check',
        4: 'v-app-radio',
        5: 'v-app-selectbox',
        6: 'v-app-file',
        7: 'v-app-table',
        8: 'v-app-datetime',
        9: 'a-app-calendar',
      },
    };
  },
  methods: {
    controlInputType(ruleIdList) {
      if (ruleIdList.includes(17) || ruleIdList.includes(18)) {
        return 'tel';
      }
      return 'text';
    },
    controlFormatter(ruleIdList) {
      // 半角数字のみ可
      if (ruleIdList.includes(17)) {
        return this.onlyHalfSizeNumber;
      }
      if (ruleIdList.includes(18)) {
        return this.onlyHalfSizeDate;
      }
      return null;
    },
    onlyHalfSizeNumber(value) {
      return value.replace(/[^0-9]/g, '');
    },
    onlyHalfSizeDate(value) {
      return value.replace(/[^0-9/]/g, '');
    },
    setText(param) {
      this.$store.commit('gasApplicationForm/setHasChange', true);
      this.$store.commit('gasApplicationInput/setText', param);
    },
    setSelect(param) {
      this.$store.commit('gasApplicationForm/setHasChange', true);
      this.$store.commit('gasApplicationInput/setSelect', param);
    },
    setFile(param) {
      this.$store.commit('gasApplicationForm/setHasChange', true);
      this.$store.commit('gasApplicationInput/setFile', param);
    },
    deleteFile(param) {
      this.$store.commit('gasApplicationInput/deleteFile', param);
    },
  },
  computed: {
    messagesEachType() {
      if (!this.messages) {
        return [];
      }
      const messagesEachType = [];
      this.messages.forEach((msgData) => {
        if (!messagesEachType[msgData.type]) {
          messagesEachType[msgData.type] = [];
        }
        const data = {
          msg: msgData.message,
          view_template: msgData.view_template,
        };
        messagesEachType[msgData.type].push(data);
      });
      return messagesEachType;
    },
    specialConponentNameList() {
      return CONST_STATUS.COMPONENT_NAMES;
    },
    apptypeId() {
      return this.$store.state.gasApplicationForm.application_type_id;
    },
    assetId() {
      return (index) => {
        if (this.messages[index].report_type_asset_id) {
          return this.messages[index].report_type_asset_id;
        }
        return this.messages[index].application_type_asset_id;
      };
    },
    isOptionBage() {
      if (this.label_badge_type === null) {
        return false;
      }
      if (LABEL_BADGE.REQUIRED_DOC_LABEL_BADGES[this.label_badge_type]) {
        return true;
      }
      return false;
    },
    optionLabelBadgeText() {
      return LABEL_BADGE.REQUIRED_DOC_LABEL_BADGES[this.label_badge_type];
    },
  },
};
</script>

<style scoped>
  .blue-text{
    color: #0E0D69;
  }
  .label-wrap {
    width: 260px;
    max-width: 260px;
    margin-top: 20px;
  }

  .mainWidth {
    width: 730px;
    max-width: 730px;
  }

  .message_1 {
    font-size: 13px;
  }

  .form-file-line {
    width: 100%;
  }

  .label-msg {
    font-size: 13px;
  }

  .label-msg-view2, .bottom-msg-view2 {
    color: #dc3545;
    white-space: break-spaces;
  }

  .badge {
    display: flex;
    height: 21px;
    align-items: center;
    justify-content: center;
    padding-bottom: 1px;
  }

  .label-text {
    white-space: pre-line
  }
  .original-badge{
    max-width: 195px;
  }
  .badge-type-3{
    width: 120px;
  }
  .label-msg-view7, .bottom-msg-view7 {
    color: #dc3545;
    font-weight: bold;
  }
</style>
