<template>
  <div
    :id="`scroll${pageIndex}`">
    <div
      v-for="(field, index) in pages[nowIndex].fields" :key="index"
      class="col-12 px-0">
      <v-app-field
        :fieldId='field.field_id'
        :fieldLabel="field.label"
        :fieldType="field.type"
        :description="field.description"
        :notice="field.notice"
        :is_required="field.is_required"
        :view_label="field.view_label"
        :type="field.type"
        :label="field.label"
        :is_variable="field.is_variable"
        :input_number="field.input_number"
        :min_input_number="field.min_input_number"
        :max_input_number="field.max_input_number"
        :view_template="field.view_template"
        :options.sync="field.options"
        :writable="writable"
        :assets="assets"
        :rules="field.rules"
        :messages="field.messages"
        :params="field.params"
        :downloadFormFile="downloadFormFile"
        :downloadOpenFile="downloadOpenFile"
        :downloadApploadedFile="downloadApploadedFile"
      />
      <hr>
    </div>

  </div>
</template>

<script>
import Field from '@/components/parts/application/Field.vue';

export default {
  name: 'gasPartsForm',
  components: {
    'v-app-field': Field,
  },
  props: {
    pageIndex: { type: Number, default: 0 },
    writable: { type: Boolean, default: false },
    assets: { type: [Object, Array], default: () => {} },
    downloadFormFile: { type: Function },
    downloadOpenFile: { type: Function },
    downloadApploadedFile: { type: Function },
  },
  data() {
    return {
      nowIndex: this.pageIndex,
    };
  },
  computed: {
    nowPage() {
      return this.nowIndex + 1;
    },
    pages() {
      return this.$store.state.gasApplicationForm.pages;
    },
  },
  methods: {
  },
};
</script>

<style scoped>
  #mainWrap {
    border: solid 1px;
    height: 610px;
    /* overflow-y: scroll; */
    /* overflow-x: hidden; */
    position: relative;
    border-color: #CCC !important;
  }
</style>
