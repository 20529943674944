const AFTER_REQUIRED_1 = 1;
const AFTER_REQUIRED_2 = 2;
const AFTER_REQUIRED_3 = 3;

const REQUIRED_DOC_LABEL_BADGES = {
  [AFTER_REQUIRED_1]: '必須:留学開始1か月前までに提出',
  [AFTER_REQUIRED_2]: '必須:留学開始1か月前までに提出',
  [AFTER_REQUIRED_3]: '必須:入手次第提出',
};

export default {
  REQUIRED_DOC_LABEL_BADGES,
};
