const score = 1;
const zaidan = 2;
const rank = 3;
const check = 4;
const comment = 5;
const sum = 6;
const adjustment = 7;

const EVALUATION_TYPE = {
  SCORE: score,
  ZAIDAN: zaidan,
  RANK: rank,
  CHECK: check,
  COMMENT: comment,
  SUM: sum,
  ADJUSTMENT: adjustment,
};

const EVALUATION_TYPE_OPTION = {
  [score]: '点数評価',
  [zaidan]: '事務局調整',
  [rank]: '順位付け',
  [check]: 'チェック',
  [comment]: 'コメント',
  [sum]: '合計得点',
  [adjustment]: '調整点',
};

export default {
  EVALUATION_TYPE,
  EVALUATION_TYPE_OPTION,
};
