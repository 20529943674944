<template>
  <perfect-scrollbar
    class="py-4 px-2 ContentsBox"
    :options='scrollOption'>

    <div class="px-3">
      <b-row class="mt-2">
        <b-col sm="3" class="label">
          <label class="labelText bold">申請者氏名</label>
        </b-col>
        <b-col sm="9" class="flex flexMiddle" v-if="userName">
          {{userName[0].field_answer_text}}
          <span
            v-if="userName[1]"
            class="ml-2">{{userName[1].field_answer_text}}</span>
        </b-col>
      </b-row>

      <hr>

      <b-row class="mt-2">
        <b-col sm="3" class="label">
          <label class="labelText bold">申請番号</label>
        </b-col>
        <b-col sm="9" class="flex flexMiddle">
          {{base.code}}
        </b-col>
      </b-row>

      <hr>

      <b-row class="mt-2">
        <b-col sm="3" class="label">
          <label class="labelText bold">現在のステータス</label>
        </b-col>
        <b-col sm="9" class="flex flexMiddle">
          {{statusTexts[base.status]}}
        </b-col>
      </b-row>

      <hr>

      <b-row
        v-if="items[constEval.SCORE] && sumScores && assigns.length > 0"
        class="mt-2">
        <b-col sm="3" class="label">
          <label class="labelText bold">平均点</label>
        </b-col>
        <b-col sm="9" class="flex flexMiddle">
          {{sumScores.avg}} 点
        </b-col>
      </b-row>

      <hr v-if="items[constEval.SCORE] && sumScores && assigns.length > 0">

      <b-row class="">
        <b-col sm="3" class="label">
          <label class="labelText bold">合否</label>
        </b-col>
        <b-col sm="4">
          <b-form-select
            :disabled="!hasAdoptionAuth || !onGoing"
            class="h-50px"
            @change="$emit('setJudgeStatus', $event)"
            :value="judgeStatus"
            :options="judgmentOption">
            <template v-slot:first>
              <option :value="null">-- 選択してください。 --</option>
            </template>
          </b-form-select>
        </b-col>
      </b-row>

      <hr v-if="isLast && isAward">

      <b-row
        v-if="isLast && isAward"
        class="mt-2">
        <b-col sm="3" class="label">
          <label class="labelText bold">Award</label>
        </b-col>
        <b-col sm="4" class="flex flexMiddle">
          <b-form-select
            :disabled="!hasAdoptionAuth || !onGoing"
            class="h-50px"
            @change="$emit('setAward', $event)"
            :value="award"
            :options="awardOptions"
          >
            <template v-slot:first>
              <option :value="null">-- 選択してください。 --</option>
            </template>
          </b-form-select>
        </b-col>
      </b-row>

      <hr v-if="isLast">

      <b-row
        v-if="isLast"
        class="mt-2">
        <b-col sm="3" class="label">
          <label class="labelText bold">助成金額</label>
        </b-col>
        <b-col sm="4" class="flex flexMiddle">
          <b-input-group>
          <b-form-input
            :disabled="!hasAdoptionAuth || !onGoing"
            @input="$emit('setGrantedMoney', $event)"
            :value="grantedMoney"
            class="h-50px"/>
          <b-input-group-append
            class="ml-1 flex flexMiddle">
            千円
          </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>

    </div>

    <!-- 審査 -->
    <div
      v-if="assigns.length > 0"
      class="eval-wrap mt-4 p-3">
      <div
        v-for="(viewScoreItemList, listIndex) in viewScoreItemLists" :key="`list_${listIndex}`"
        class="my-2">
        <!-- 一段目 -->
        <div class="flex line-head mx-2 line-min">
          <div class="score-head score-name">
            審査委員氏名
          </div>
          <div
            v-for="(item, itemIndex) in viewScoreItemList" :key="`item_${itemIndex}`"
            class="score-head score-item">
            {{item.name}}
          </div>
          <!-- 最終列は合計点表示 -->
          <div
            v-show="items[constEval.SCORE] && (isScoreTotalTitle(viewScoreItemList.length) || (!isShowedScoreTotalTitle && listIndex === viewScoreItemLists.length-1))"
            class="score-head score-item">
            合計点
          </div>
          <div v-if="listIndex === viewScoreItemLists.length-1" class="score-head score-item">
            再審査
          </div>
        </div>
        <!-- 二段目以降は審査委員でループ -->
        <div
          v-for="(assign, assignIndex) in assigns" :key="`assign_${assignIndex}`"
          class="flex line-body mx-2 line-min">
          <div class="score-body score-name">{{assign.name}}</div>
          <div
            v-for="(item, itemIndex) in viewScoreItemList" :key="`item_${itemIndex}`"
            :class="[
              'score-body score-item',
              item.type === 7 ? 'text-red' : '',
            ]">
            <!-- 点数表示・順位・チェック表示 -->
            {{evalItem(assign.userId, item.id, item.type)}}
          </div>
          <!-- 点数表示ありの時、最終列は合計点表示 -->
          <div
            v-if="items[constEval.SCORE] && (isScoreTotal(viewScoreItemList.length) || (!isShowedScoreTotal && listIndex === viewScoreItemLists.length-1))"
            class="score-body score-item">
            {{sumScores[assign.userId]}}
          </div>
          <div
            v-if="listIndex === viewScoreItemLists.length-1"
            class="score-body score-item">
              <b-form-checkbox
                @change="updateSelectedRetrialList($event)"
                :checked="retrialList"
                :value="assign.userId"
                :disabled="!isEvaluationPeriod || !evals[assign.userId]"
                switches
                />
          </div>
        </div>
      </div>
      <!-- コメント -->
      <div
        v-for="(item, itemIndex) in viewCommentItemList" :key="`item_${itemIndex}`">
        <!-- 一段目 -->
        <div class="flex line-head mx-2">
          <div class="score-head score-name">
            審査委員氏名
          </div>
          <div
            class="comment-head comment-item">
            {{item.name}}
          </div>
        </div>
        <!-- 二段目以降は審査委員でループ -->
        <div
          v-for="(assign, assignIndex) in assigns" :key="`assign_${assignIndex}`"
          class="flex line-body mx-2 line-min">
          <div class="score-body score-name">{{assign.name}}</div>
          <div
            class="comment-body comment-item">
            {{evalComment(assign.userId, item.id)}}
          </div>
        </div>
      </div>
    </div>


  </perfect-scrollbar>
</template>

<script>
import CONST_STATUS from '@/constants/appStatus';
import CONST_EVAL from '@/constants/evaluation';

export default {
  name: 'cmsApplicationControl',
  components: {
  },
  props: {
    base: { type: Object },
    stageData: { type: Object },
    judgeStatus: {},
    grantedMoney: {},
    award: {},
    isAward: {},
    evals: { type: [Object, Array], default: () => {} },
    items: { type: [Object, Array], default: () => {} },
    adjusts: { type: [Object, Array], default: () => {} },
    sumScores: { type: [Object, Array], default: () => {} },
    assigns: { type: Array, default: () => {} },
    hasAdoptionAuth: { type: Boolean },
    onGoing: { type: Boolean },
    userName: { type: [Object, Array], default: () => {} },
    authAlternate: { type: Array, default: () => {} },
    retrialList: { type: Array, default: () => {} },
    isEvaluationPeriod: { type: Boolean },
  },
  data() {
    return {
      nowIndex: this.pageIndex,
      statusTexts: CONST_STATUS.APP_STATUS_TEXTS,
      scrollOption: {
        suppressScrollX: true,
      },
      constEval: CONST_EVAL.EVALUATION_TYPE,
      selectedJudgingUserIdList: [],
      isShowedScoreTotalTitle: false,
      isShowedScoreTotal: false,
    };
  },
  methods: {
    isShowedScoreTotalTitleFlg(flg) {
      this.isShowedScoreTotalTitle = flg;
    },
    isShowedScoreTotalFlg(flg) {
      this.isShowedScoreTotal = flg;
    },
    updateSelectedRetrialList(list) {
      this.$emit('updateRetrialList', list);
    },
  },
  computed: {
    isScoreTotalTitle() {
      return (itemLength) => {
        if (itemLength === 7) {
          this.isShowedScoreTotalTitleFlg(true);
          return true;
        }
        return false;
      };
    },
    isScoreTotal() {
      return (itemLength) => {
        if (itemLength === 7) {
          this.isShowedScoreTotalFlg(true);
          return true;
        }
        return false;
      };
    },
    userInfo() {
      return this.$store.state.userInfo.inputData;
    },
    judgmentOption() {
      const text = CONST_STATUS.JUDGMENT_STATUS_OPTION;
      const option = [];
      option.push({ value: '1', text: text['1'] });
      option.push({ value: '0', text: text['0'] });
      // 「日本人留学生助成」「外国人留学生助成」「語学留学助成」にのみ
      if (this.authAlternate.indexOf(this.base.name) !== -1) {
        option.push({ value: '14', text: text['14'] });
      }
      option.push({ value: '16', text: text['16'] });
      option.push({ value: '15', text: text['15'] });
      return option;
    },
    // 一列当たり、コメントを除き9個の配列を作成する
    viewScoreItemLists() {
      let index = 0;
      let count = 0;
      const itemLists = [[]];
      Object.keys(this.items).forEach((type) => {
        const intType = Number(type);
        if (intType === this.constEval.SCORE || intType === this.constEval.ZAIDAN
        || intType === this.constEval.RANK || intType === this.constEval.CHECK || intType === this.constEval.ADJUSTMENT) {
          this.items[type].forEach((item) => {
            count += 1;
            itemLists[index].push(item);
            // 8個ちょうどの時は最後のindexは空配列になる。合計点を表示するために必要。
            if (count === 7) {
              index += 1;
              count = 0;
              if (!itemLists[index]) {
                itemLists[index] = [];
              }
            }
          });
        }
      });
      return itemLists;
    },
    evalItem() {
      return (userId, itemId, type) => {
        // itemIdが事務局調整のものであれば、そちらを参照する
        if (this.adjusts[itemId]) {
          return this.adjusts[itemId].score;
        }
        // なかったら空
        if (!this.evals[userId] || !this.evals[userId].items[itemId]) {
          return '';
        }
        // 点数・事務局長製・点数調整の時
        if (type === this.constEval.SCORE || type === this.constEval.ZAIDAN || type === this.constEval.ADJUSTMENT) {
          return this.evals[userId].items[itemId].evaluation;
        }

        // ランクの場合
        if (type === this.constEval.RANK) {
          return `${this.evals[userId].items[itemId].evaluation}位`;
        }
        // チェックの場合
        return this.evals[userId].items[itemId].evaluation === 1 ? '有' : '無';
      };
    },
    viewCommentItemList() {
      return this.items[this.constEval.COMMENT] || [];
    },
    evalComment() {
      return (userId, itemId) => {
        // なかったら空
        if (!this.evals[userId] || !this.evals[userId].items[itemId]) {
          return '';
        }
        return this.evals[userId].items[itemId].comment;
      };
    },
    isLast() {
      return this.stageData.is_last === 1;
    },
    awardOptions() {
      return [
        { value: 1, text: '奨励賞' },
        { value: 2, text: 'Award' },
      ];
    },
  },
  // ロード画面
  created() {
  },
};
</script>

<style>
  .table thead th {
    border-top-width: 0;
    min-height: 120px;
  }

  .table thead th, .table td {
    border-color: #BBB !important;
  }

  /* 前段階評価 */
  #evalViewTable thead th:nth-of-type(1), #evalCommentTable thead th:nth-of-type(1) {
    width: 140px !important;
    padding: 5px;
    text-align:center;
  }
  #evalViewTable tbody td:not(:first-of-type) {
    text-align:center;
  }
  #evalViewTable thead th:not(:first-of-type) {
    padding: 5px;
    text-align:center;
  }
  #evalViewTable thead th:last-of-type {
    width: 130px !important;
    padding: 5px;
  }
  #evalCommentTable>tbody>tr>td {
    white-space:pre-wrap;
    word-wrap:break-word;
  }

</style>

<style scoped>
  .opacity03 {
    opacity: 0.3;
  }

  .h-50px {
    height: 50px;
  }

  .label {
    height: 50px;
  }

  .labelText {
    position: absolute;
    top: 10px;
  }

  .eval-wrap {
    background-color: #E6E6E6;
  }

  .score-input:disabled {
    background-color: #CCC !important;
    width: 130px;
    border: #BBB solid 1px;
  }

  .error {
    color: #dc3545;
  }

  .score-head {
    background-color: #6E6E6E;
    color: #FFF;
    margin-right: 1px;
    min-height: 60px;
    display: flex;
    -webkit-align-items: center; /* 縦方向中央揃え（Safari用） */
    align-items: center; /* 縦方向中央揃え */
    -webkit-justify-content: center; /* 横方向中央揃え（Safari用） */
    justify-content: center; /* 横方向中央揃え */
    padding: 5px;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
  }
  .score-name {
    width: 150px;
  }
  .score-item {
    width: 107px;
  }
  .score-body {
    margin-right: 1px;
    height: 50px;
    display: flex;
    -webkit-align-items: center; /* 縦方向中央揃え（Safari用） */
    align-items: center; /* 縦方向中央揃え */
    -webkit-justify-content: center; /* 横方向中央揃え（Safari用） */
    justify-content: center; /* 横方向中央揃え */
    padding: 5px;
    font-size: 16px;
  }

  .comment-head {
    background-color: #6E6E6E;
    color: #FFF;
    margin-right: 1px;
    height: 60px;
    display: flex;
    -webkit-align-items: center; /* 縦方向中央揃え（Safari用） */
    align-items: center; /* 縦方向中央揃え */
    -webkit-justify-content: center; /* 横方向中央揃え（Safari用） */
    justify-content: center; /* 横方向中央揃え */
    padding: 5px;
    font-size: 12px;
    font-weight: bold;
    width: 850px;
    text-align: center;
  }
  .comment-body {
    margin-right: 1px;
    display: flex;
    -webkit-align-items: center; /* 縦方向中央揃え（Safari用） */
    align-items: center; /* 縦方向中央揃え */
    padding: 5px;
    font-size: 14px;
    width: 850px;
    white-space:pre-wrap;
    word-wrap:break-word;
  }

  .line-min {
    width: min-content;
  }

  .line-body {
    border-bottom: solid 1px #bbb;
  }

  .text-red {
    color: #dc3545;
  }
</style>
